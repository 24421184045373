.sentence-details {
	&.closed {
		filter: blur(3px);
		opacity: 0.5;
		pointer-events: none;
	}

	&.sentence-details--multi {
		top: 0;
		padding-top: 0;

		.sentence-details__title {
			background: #f0f2f9;
			padding-left: 15px;
			font-size: 16px;
			border-bottom: 1px solid $separatorGray;
			font-weight: bold;
			line-height: 58px;
		}
	}

	.sentence-details__text {
		color: #555;
		font-style: italic;
		font-size: 16px;
		padding: 15px 25px 25px;
		border-bottom: 1px solid $separatorGray;

		.sentence-details__text__title {
			text-align: center;
		}
	}

	.sentence-details__box {
		padding: 25px;

		h2 {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}

	.sentence-details__form {
		.ant-form-item {
			margin-bottom: 10px;
		}

		.ant-form-item-label {
			line-height: 20px;
			height: 20px;
		}
	}

	.sentence-details__history {
		padding-bottom: 0;

		.sentence-details__history__title {
			font-size: 16px;
			display: block;
			margin-bottom: 5px;
			transform: translateY(-2px);
		}

		strong {
			margin-left: 5px;
		}

		.ant-tag {
			padding: 0 3px;
			font-size: 10px;
		}

		.sentence-details__player {
			audio {
				height: 40px;
				margin-left: -20px;
			}
		}
	}

	.sentence-details__marker {
		border-bottom: 1px solid $separatorGray;

		a {
			display: block;
			float: left;
			width: 25px;
			height: 20px;
			border: 1px solid transparentize(#999, 0.8);
			border-right: none;
			text-align: center;

			.anticon {
				font-size: 12px;
				transform: translateY(-7px);
			}

			&.sentence-details__marker__color--yellow {
				background: transparentize(yellow, 0.5);
			}
			&.sentence-details__marker__color--purple {
				background: transparentize(purple, 0.5);
			}
			&.sentence-details__marker__color--orange {
				background: transparentize(orange, 0.5);
			}

			&:first-child {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
			}

			&:last-child {
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;
				border-right: 1px solid transparentize(#999, 0.8);
			}
		}
	}
}

.banner {
	@include card;
	background: #136dfb;
	padding: 20px;

	h1 {
		margin-top: 0;
		font-size: 22px;
		color: white;
	}

	p {
		color: #cfe1ff;
		margin-bottom: 25px;
	}
}

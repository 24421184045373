.lexicon-edit {
	.ant-form-item {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&-children {
			display: block;
		}

		.anticon {
			transform: none;
		}
	}
}

.missing-word-form {
	width: 700px !important;

	&__initial {
		padding: 10px;
		border-bottom: 1px solid #ddd;
	}

	&__variants {
		& > .ant-btn {
			margin: 10px;
		}

		&__item {
			background: #f9f9f9;
			border-top: 1px solid #ddd;
			padding: 10px;

			.col-1 a {
				margin-left: -3px;
			}
		}
	}

	.ant-spin {
		margin: 25px;
	}

	.ant-select {
		width: 200px;
		margin-right: 10px;
	}

	input {
		font-family: monospace;
	}
}

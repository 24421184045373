.word-level {
	position: fixed;
	right: 32%;
	top: 40px;
	width: 450px;
	background: red;
	padding: 15px;
	background: white;
	z-index: 3;

	.word-level__close {
		position: absolute;
		right: 15px;
		top: 10px;
		cursor: pointer;
		z-index: 2;
	}

	.word-level__form {
		.ant-form-item {
			margin-bottom: 10px;
		}

		.ant-form-item-label {
			line-height: 20px;
			height: 20px;
		}
	}
}

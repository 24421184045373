.user-selector {
	&__item {
		padding: 10px 0;
		border-bottom: 1px solid #ddd;

		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
			border-bottom: none;
		}

		.ant-checkbox-wrapper {
			margin-right: 7px;
		}
	}
}

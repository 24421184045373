.ant-modal-body {
	.ant-form-item {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&-label {
			font-size: 15px;
			line-height: 15px;
			font-weight: 500;
		}
	}
}

/* HoveringDiv.css */

.hovering-div {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    width: 150px;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    padding: 8px;
    font-weight: bold;

}

.hovering-div.expanded {
    width: auto;
    height: auto;

}

.scrollable-list {
    max-height: 300px;
    overflow-y: auto
}

.centered-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    height: 20px;
    margin-top: 10px;
}
.cluster-form {
	@include card;

	padding: 25px;

	h1 {
		background: #fafafa;
		font-weight: 500;
		font-size: 23px;
		padding: 20px 25px;
		margin: -25px -25px 25px;
	}

	.ant-steps {
		margin-bottom: 25px;
		height: 40px;
	}

	.ant-form-item {
		&-label {
			height: 35px;

			label {
				font-weight: 500;
				line-height: 20px;
				font-size: 18px;
			}
		}

		.anticon {
			transform: none;
		}

		.ant-form-item-children {
			position: static;
		}
	}
}

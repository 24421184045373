$panelBackground: #f7f8fa;

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
	box-sizing: border-box;
}

html,
body {
	margin: 0;
	padding: 0;
	height: 100%;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.ant-layout,
.ant-layout-footer {
	background: white;
}

.anticon {
	transform: translateY(-3px);
}

.ant-alert-description ul {
	margin-bottom: 0;
}

.ant-modal-mask,
.ant-modal-wrap {
	z-index: 10000;
}

.search {
	.search__resynthesize {
		position: fixed;
		bottom: 0;
		left: 26rem;
		right: 0;

		button {
			margin-left: 10px;
			margin-top: 5px;
		}

		.ant-form-item {
			margin-bottom: 5px;

			.ant-form-item-children {
				position: relative;
				display: block;

				.ant-select-arrow-icon {
					transform: translateY(0px);
				}

				.ant-form-item-children-icon {
					.anticon {
						transform: translateY(-7px) translateX(-5px);
					}
				}
			}
		}

		.ant-tabs {
			.ant-tabs-nav-wrap {
				background: hsla(220, 23%, 93%, 1);
			}

			.ant-tabs-content {
				height: 113px;
				padding: 15px;
			}

			.ant-tabs-left-bar {
				.ant-tabs-tab {
					padding: 8px 24px;
					margin-bottom: 0;
					border-bottom: 1px solid #ddd;

					&.ant-tabs-tab-active {
						font-weight: normal !important;
					}
				}
			}
		}
	}
}

.time-tracker {
	position: fixed;
	bottom: 0;
	right: 520px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 10px;
	background: $panelBackground;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.status-updater-button {
	&--review {
		border-color: orange;
		font-weight: bold;
	}

	&--ready {
		border-color: green;
		font-weight: bold;
	}
}

.status-updater {
	&__section {
		p {
			font-style: italic;
			margin-bottom: 5px;
			margin-top: 30px;
		}
	}
}

.epub-parser {
	display: flex;
	margin: -23px;

	&__side {
		width: 300px;
		padding: 15px;
		height: 100%;

		div {
			max-height: 250px;
			overflow: auto;
			margin-bottom: 15px;
		}

		h2 {
			font-size: 20px;
		}

		label {
			display: block;
		}
	}

	&__preview {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		border-right: #ddd;
		background: #f9f9f9;
		overflow: auto;

		h2 {
			font-size: 18px;
			padding: 10px;

			.title {
				margin-left: 10px;
				display: inline-block;
				transform: translateY(2px);
			}
		}

		iframe {
			width: 100%;
			flex-grow: 1;
			border: none;
		}
	}
}

.dashboard-layout {
	min-height: 100vh;
	padding-bottom: 50px;
	background: $panelBackground;

	&__header {
		background: white;
		padding: 15px 0;

		&__logo {
			float: left;

			img {
				height: 30px;
			}
		}

		&__auth {
			float: right;

			.badge {
				margin-left: 10px;
			}

			.anticon {
				transform: translateY(-3px) !important;
			}
		}
	}

	&__content {
		margin-top: 20px;
	}
}
